//***************************************************************

export const SUBSCRIBE_LAMBDA_ENDPOINT = "https://03p5svtfj4.execute-api.us-west-2.amazonaws.com/prod/ezw-subscribe/"
export const EZW_APP_STORE_URL = "https://apps.apple.com/us/app/easywellness/id1579425482"
export const EZW_BLOG_URL = "https://medium.com/little-by-little-labs"
export const LBL_URL = "https://www.littlebylittlelabs.com"
export const MEDIUM_RSS_FEED = "https://medium.com/feed/little-by-little-labs"
export const MEDIUM_JSON = `https://api.rss2json.com/v1/api.json?rss_url=${MEDIUM_RSS_FEED}`
export const EZW_INSTAGRAM_URL = "https://instagram.com/easywellness.app/"
export const EZW_FACEBOOK_URL = "https://facebook.com//easywellness.app/"
export const CONTENT_FUL_JSON = `https://cdn.contentful.com/spaces/fsby0n3iwgpm/environments/master/entries?access_token=zl-HX6JuT2mILt2MukJpvC0jzGrVHGieQFiqbqfprgE`

//***************************************************************
