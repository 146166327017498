import { useRef, useState, useEffect } from "react"
import Logo from "../images/Logo.png"
import AppStoreImage from "../images/AppStore.png"
import MissionRocket from "../images/Mission.png"
import Pillar1 from "../images/Pillar1.png"
import Pillar2 from "../images/Pillar2.png"
import Pillar3 from "../images/Pillar3.png"
import Pillar4 from "../images/Pillar4.png"
import Bulb from "../images/Bulb.png"
import { SUBSCRIBE_LAMBDA_ENDPOINT, EZW_APP_STORE_URL } from '../constants'
import SlideInSection from "./SlideInSection"
import { fetchContentFulData } from '../actions'
import { BlogCard } from "./BlogCard"
import { Footer } from "./Footer"

//*********************************************************************/

export default function Home() {
  
  //*********************************************************************/

  const [showSpinner, setShowSpinner] = useState(false)
  const [email, setEmail] = useState("")
  const [subscribed, setSubscribed] = useState(false)
  const [error, setError] = useState(false)
  const [blogFeed, setBlogFeed] = useState({})
  const [loadingBlogs, setLoadingBlogs] = useState(true)

  //*********************************************************************/

  const missionRef = useRef(null)
  const whatsInsideRef = useRef(null)
  const joinUsRef = useRef(null)
  const blogRef = useRef(null)

  //*********************************************************************/

  useEffect(() => {

    fetchContentFulData().then((json) => {

      setBlogFeed(json)

      setLoadingBlogs(false)
    })

  }, [])

  //*********************************************************************/

  const subscribe = () => {
    setShowSpinner(true)

    if (validateEmail(email) === false) {
      setShowSpinner(false)
      setError(true)
      return
    }

    fetch(SUBSCRIBE_LAMBDA_ENDPOINT, {
      method: "PUT",
      cache: "no-cache",
      headers: { "Content-Type": "application/json" },
      referrerPolicy: "no-referrer",
      body: JSON.stringify({ email: email })
    })
      .then(res => res.json())
      .then(
        (result) => {
          setShowSpinner(false)
          setError(false)
          setSubscribed(true)
        },
        (error) => {
          setError(true)
        }
      )
  }

  //*********************************************************************/

  const emailInputUpdated = (email) => {
    setShowSpinner(false)
    setError(false)
    setSubscribed(false)
    setEmail(email)
  }

  //*********************************************************************/

  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase())
  }
  
  //*********************************************************************/

  return (
    <div className="App">

      <div className="NavContainer">
        <div className="Nav">
          <img className="AppLogo" src={Logo} alt="EasyWellness" />
          <div id="NavLinks">
            <div onClick={() => window.scrollTo({ top: missionRef.current.offsetTop - 30 })}>Our Mission</div>
            <div onClick={() => window.scrollTo({ top: whatsInsideRef.current.offsetTop - 30 })}>What's Inside</div>
            <div onClick={() => window.scrollTo({ top: joinUsRef.current.offsetTop - 30 })}>Join</div>
            <div onClick={() => window.scrollTo({ top: blogRef.current.offsetTop - 30 })}>Blog</div>
            <div><a href={EZW_APP_STORE_URL} target="_blank" rel="noreferrer">Download</a></div>
          </div>
        </div>
      </div>

      <div id="RowAContainer">
        <div id="RowA">
          <div id="RowALeft">
            <h1 className="Heading">Traditional workouts aren't for everyone.</h1>
            <div className="SubHeading">
              We know it's hard to get healthier. EasyWellness can help you make
              lasting changes that actually fit your lifestyle. <br />
              <br />
              EasyWellness offers foundational health programs for different body
              types and endurance levels. Our features are built to help you stay
              on track - even when you're busy or lack motivation!
            </div>
            <a href={EZW_APP_STORE_URL} target="_blank" rel="noreferrer">
              <img id="AppStoreButton" src={AppStoreImage} alt="Download" />
            </a>
          </div>
          <SlideInSection><div id="RowARight" className="AnimatedSection"/></SlideInSection>
        </div>
      </div>
      
      <div id="RowBContainer" ref={missionRef}>
        <div id="RowB">
          <img src={MissionRocket} alt="" />
          <div className="Heading" style={{ fontSize: 34, margin: "20px 0px 0px" }}>Our mission</div>
          <div className="SubHeading">Make it easier for anyone to lead a healthier lifestyle.</div>
        </div>
      </div>

      <div id="RowCContainer" ref={whatsInsideRef}>
        <div className="Heading">What's inside?</div>
        <div className="Pillars">
          <div className="Pillar">
            <SlideInSection fromLeft={true}><img src={Pillar1} alt="" /></SlideInSection>
            <div className="Spacer"></div>
            <div className="PillarContent">
              <div className="PillarName">Stretch</div>
              <div className="SubHeading">Stretching routines to improve flexibility and mobility at any age.  No matter your age or body type, EasyWellness stretching routines are the key to flexibility and mobility so that you can complete daily activities easily and without injury. Stretching can also help with pain and posture.</div>
              <a href={EZW_APP_STORE_URL} target="_blank" rel="noreferrer">
                <img id="AppStoreButtonSmall" src={AppStoreImage} alt="Download" />
              </a>
            </div>
          </div>
          <div className="Pillar PillarReverse">
            <div className="PillarContent">
              <div className="PillarName">Breathe</div>
              <div className="SubHeading">EasyWellness breathing exercises will help you manage stress and get better sleep. By completing EasyWellness breathing exercises, you improve not just your physical but mental health.</div>
              <a href={EZW_APP_STORE_URL} target="_blank" rel="noreferrer">
                <img id="AppStoreButtonSmall" src={AppStoreImage} alt="Download" />
              </a>
            </div>
            <div className="Spacer"></div>
            <SlideInSection fromLeft={false}><img src={Pillar2} alt="" /></SlideInSection>
          </div>
          <div className="Pillar">
            <SlideInSection fromLeft={true} ><img src={Pillar3} alt="" /></SlideInSection>
            <div className="Spacer"></div>
            <div className="PillarContent">
              <div className="PillarName">Hydrate</div>
              <div className="SubHeading">Staying hydrated and drinking more water can be difficult, but not with the EasyWellness Water Tracker. Improve digestion and curb cravings when you hit your daily water needs.</div>
              <a href={EZW_APP_STORE_URL} target="_blank" rel="noreferrer">
                <img id="AppStoreButtonSmall" src={AppStoreImage} alt="Download" />
              </a>
            </div>
          </div>
          <div className="Pillar PillarReverse">
            <div className="PillarContent">
              <div className="PillarName">Move</div>
              <div className="SubHeading">The EasyWellness Step Counter can assist with weight management, cardiovascular health and can boost your mood. Walking has incredible benefits as a form of exercise and is low impact. If you are a beginner, this is a great way to create a walking habit.</div>
              <a href={EZW_APP_STORE_URL} target="_blank" rel="noreferrer">
                <img id="AppStoreButtonSmall" src={AppStoreImage} alt="Download" />
              </a>
            </div>
            <div className="Spacer"></div>
            <SlideInSection fromLeft={false}><img src={Pillar4} alt="" /></SlideInSection>
          </div>
        </div>
      </div>

      <div id="RowDContainer">
        <div id="RowD">
          <div className="Benefits">
            <div className="Benefit">
              <img src={Bulb} alt="" />
              <div className="SubHeading">Quick, easy health programs for all ages and body types.</div>
            </div>
            <div className="Benefit">
              <img src={Bulb} alt="" />
              <div className="SubHeading">Habits you can keep up long term without burning out.</div>
            </div>
            <div className="Benefit">
              <img src={Bulb} alt="" />
              <div className="SubHeading">Fun tips and lessons so you can make the most out of your routine.</div>
            </div>
            <div className="Benefit">
              <img src={Bulb} alt="" />
              <div className="SubHeading">Gentle guidance that allows you to go at your own pace.</div>
            </div>
          </div>
        </div>
      </div>

      <div id="RowE" ref={blogRef}>
        <div style={{ marginBottom: 40 }} className="Heading2">Articles{loadingBlogs}</div>
        <div className="BlogListGrid">
          {!loadingBlogs && blogFeed.items.map((item, index) => {
            return <BlogCard key={index} blog={item} />
          })}
        </div>
      </div>

      <div id="RowF" ref={joinUsRef}>
        <div className="Heading2">Join our community</div>
        <div className="SubHeading">Better health can be easy. We'll show you how. <br/>Stay tuned for offers, promotions and more!</div>
        {!subscribed && <div className="Email">
          <input type="email" className="Input" placeholder="Sign me up!" onChange={e => emailInputUpdated(e.target.value)}/>
          {!showSpinner && <div id="EmailSubmitBtn" onClick={subscribe}></div>}
          {showSpinner && <div id="Spinner"></div>}
        </div>}
        {subscribed && <p id="EmailSubmitMessage">Success! Thank you for signing up!</p>}
        {error && <p id="EmailError">Invalid email. Please enter a valid email address.</p>}
      </div>

      <Footer/>
    </div>
  );
}
