
import { MEDIUM_JSON } from './constants'
import * as contentful from 'contentful'

//*********************************************************************/

export const fetchBlogData = async () => {
  
  try {
      
    const response = await fetch(MEDIUM_JSON);
      
    const json = await response.json()
      
    return json

  } catch (error) {
      
    console.log("error", error)

    return {}
  }
}

//*********************************************************************/

export const fetchContentFulData = async () => {
  
  try {
      
    const client = contentful.createClient({
      space: 'fsby0n3iwgpm',
      environment: 'master',
      accessToken: 'zl-HX6JuT2mILt2MukJpvC0jzGrVHGieQFiqbqfprgE'
    })

    const response = await client.getEntries()
      
    return response

  } catch (error) {
      
    console.log("error", error)

    return {}
  }
}

//*********************************************************************/
