import { Link } from 'react-router-dom'
import { blogUrlParam } from "../utilities"

//*********************************************************************/

export const BlogCard = ({ blog }) =>  {
  
  const pubDate = new Date(blog.fields.publishedDate).toDateString()

  //*********************************************************************/

  return (

    <Link to={'/blog/'+ blogUrlParam(blog.fields.title)} >
      <div className="BlogCard">
        
          <img src={blog.fields.bannerImage.fields.file.url} alt="thumb"/>
          <div style={{ margin: "8px 20px 0 20px" }}>
            <div  className="Heading3">{blog.fields.title}</div>
            <div style={{ margin: "10px 0" }}>{blog.fields.subTitle}</div>
            <div style={{ margin: "20px 0", color: "gray", fontWeight: 500 }}>{pubDate}</div>
          </div>
      </div>
    </Link>
  )

  //*********************************************************************/
}