import { useEffect } from "react";

export default function Privacy() {

  useEffect(() => {
    document.title = "EasyWellness | Privacy Policy"
  }, [])

  return (
    <div id="Privacy" style={{ margin: 30 }}>
      <p style={{ textAlign: "right" }}>
        <span>Updated: 09/15/2021</span>
      </p>
      <h2 style={{ textAlign: "center" }}>
        <strong>PRIVACY POLICY</strong>
      </h2>
      <p>
        <span>
        This Privacy Policy serves to describe how and what type of information Little by Little Labs, LLC., (“Company”, “Us”, “Our”, “We”) collects from you (“User”) on our mobile app, EasyWellness (the “Service(s)”, “Product”, “App”) and website EasyWellness.app (“Website”). Little by Little Labs, LLC., is the creator of the EasyWellness App. This document informs you of our practices which include: collection, use, protection and disclosure of Personal Information provided by the user when using our Service and of the choices you have associated with that information.
        </span>
      </p>
      <br />
      <p>
        <strong>CHANGES TO OUR POLICIES</strong>
      </p>
      <p>
        <span>
          At Little by Little Labs, LLC., we respect your privacy. We reserve
          the right to make changes to this policy from time to time. Please
          review our Privacy Policy regularly as continued usage of our Service
          establishes acceptance of updated policies. You will be notified of
          any updates by the change in date at the top of the policy. <br />
        </span>
      </p>

      <p>
        <span>
          Please read our Privacy Policy carefully. By using any of our
          Services, including our websites, mobile apps, or any other future
          services, you are agreeing and consenting to the practices described
          in this Privacy Policy. The Personal Information collected is used for
          providing and improving the Service. If you do not agree to our
          policies, you choose not to use our website, app or any other
          Services. <br />
        </span>
      </p>
      <p>
        <span>
          The terms used in this Privacy Policy have the same meanings as in our
          Terms of Service, which is accessible at easywellness.app/terms, unless otherwise
          defined in this Privacy Policy.
        </span>
      </p>
      <br />
      <p>
        <strong>INFORMATION COLLECTION AND USE</strong>
      </p>
      <p>
        <span>
          Personal Information/Data described in this Privacy Policy may be
          collected through:
        </span>
        <span>
          <br />
        </span>
      </p>

      <ul>
        <li>
          <span>
            Our website
            <span>
              <br />
            </span>
          </span>
        </li>
        <li>
          <span>
            <span>
              Our mobile app
              <span>
                <br />
              </span>
            </span>
          </span>
        </li>
        <li>
          <span>
            <span>
              Through email correspondence or any other electronic messages.
            </span>
          </span>
        </li>
        <li>
          <span>
            <span>
              Tracking technologies. See “Tracking Technologies.”
            </span>
          </span>
        </li>
      </ul>

      <p>
        <span>Types of data/information we collect:</span>
        <span>
          <br />
        </span>
      </p>

      <ul>
        <li>
          <span>
            Email
            <span>
              <br />
            </span>
          </span>
        </li>
        <li>
          <span>
            Nickname (an alias provided by the user)
            <span>
              <br />
            </span>
          </span>
        </li>
        <li>
          <span>
            Age range
            <span>
              <br />
            </span>
          </span>
        </li>
        <li>
          <span>
            Gender (including a &ldquo;Prefer not to say&rdquo; option)
          </span>
          <span>
            <br />
          </span>
        </li>
        <li>
          <span>
            User Goals (reasons the user is interested in the app)
            <span>
              <br />
            </span>
          </span>
        </li>
        <li>
          <span>
            <span>App usage and activity data&nbsp;</span>
          </span>
        </li>
      </ul>

      <p>
        <span>How we use your data/information:</span>
        <span>
          <br />
        </span>
      </p>

      <ul>
        <li>
          <span>
            to notify you about new Products and Services, release
            announcements, updates
            <span>
              <br />
            </span>
          </span>
        </li>
        <li>
          <span>
            to improve our Products and Services, resolve technical issues
          </span>
          <span>
            <br />
          </span>
        </li>
        <li>
          <span>to understand User&rsquo;s usage and preferences </span>
          <span>
            <br />
          </span>
        </li>
        <li>
          <span>to provide customer support</span>
        </li>
      </ul>

      <p>
        <span>
          Little by Little Labs, LLC., will take all reasonable steps to ensure
          that your information is secure and in accordance with this Privacy
          Policy.&nbsp;
        </span>
      </p>
      <br />
      <p>
        <strong>SECURITY</strong>
      </p>
      <p>
        <span>
          Little by Little Labs, LLC., strives to use commercially acceptable
          means of protecting your Personal Information. Please remember that no
          method of transmission over the internet, or method of electronic
          storage is 100% secure and reliable, and we cannot guarantee its
          absolute security.
        </span>
      </p>
      <br />

      <p>
        <strong>TRACKING TECHNOLOGIES AND MARKETING OPT OUT</strong>
      </p>
      <p>
        <span>
        Little by Little Labs, LLC. uses Facebook and Google to manage advertising of our Services across various websites and platforms and across your other devices based on your past visits to our App and Website. Third party partners may use cookies and other technologies to gather data about your usage and activities within our App and Website for retargeting ads or other such advertising. We cannot respond to do-not-track signals. You can learn more about this at: www.aboutads.info/choices. If you would like to opt out of receiving ads, you will have to change your ad preferences in your Google account.

        Little by Little Labs, LLC. uses Facebook and Google to manage the marketing of our Services across various websites and platforms. If you visit our Website or App, a Facebook Pixel that allows us to advertise to you on Facebook will be attached to your browser.

        You can opt out of tracking by contacting us at info@littlebylittlelabs.com.
        We do not sell your personal information to third parties.
        </span>
      </p>

      <p>
        <strong>ANALYTICS</strong>
      </p>
      <p>
        <span>
        In order to offer the best Service possible, we use analytics to understand user behavior. We use third-party Service Providers, such as Google Firebase, to analyze the use and functionality of the App. 
        </span>
        
        <br />
        <p>
          <span style={{ "text-decoration": "underline" }}>
            Firebase Analytics (Google)
          </span>
          <p>
          Firebase Analytics can collect a user's mobile ad ID, IDfV ID (iOS) or Android ID, instance ID, and Analytics app instance ID for analytics purposes
          </p>
        </p>

        <p>
          <span style={{ "text-decoration": "underline" }}>
            Firebase Crashlytics (Google)
          </span>
          <p>
            Crashlytics is a monitoring service provided by Google. Personal Data collected: geographic position, unique device identifiers for advertising, various types of Data as specified in the Firebase privacy policy.
            We do not use “cookies”.
            <br /><br />
            Please review the Firebase Privacy Policy page here: <a href="https://firebase.google.com/support/privacy/">Firebase Privacy Policy</a>  
          </p>
        </p>
        <p>
          <span style={{ "text-decoration": "underline" }}>
            RevenueCat
          </span>
          <p>
            RevenueCat helps to manage in-app purchase functionality such as trial versions and paid subscriptions. When you make an in-app purchase, RevenueCat receives a receipt with information about that purchase. RevenueCat does not receive or process payment information such as a credit card number.
            
            <br /><br />
            Please review the RevenueCat Privacy Policy page here: <a href="https://www.revenuecat.com/privacy">RevenueCat Privacy Policy</a>
          </p>
        </p>
        <p>
          <span style={{ "text-decoration": "underline" }}>
            MixPanel
          </span>
          <p>
            MixPanel tracks user behavior within the App. More information about how that information is restricted is available at the following links: <a href="https://mixpanel.com/terms/" >MixPanel Terms of Service</a> and <a href="https://mixpanel.com/privacy/">MixPanel Privacy Policy</a>. You have the ability to opt-out of MixPanel’s automatic retention of data collected at the following link: <a href="https://mixpanel.com/optout/">MixPanel Opt-Out</a>. Should you choose to opt out, Mixpanel places a persistent opt-out cookie on your device. You will need to opt-out again if you get a new computer, install a new browser or erase/change your browser’s cookie file.
          </p>
        </p>

      </p>
      <br />

      <p>
        <strong>DATA DISCLOSURE</strong>
      </p>
      <p>
        <span>Legal Requirements</span>
      </p>
      <p>
        <span>
          Little By Little Labs, LLC., may disclose your personal information if
          it is necessary to:
        </span>
        <span>
          <br />
        </span>
      </p>

      <ul>
        <li>
          <span>
            comply with a legal obligation
            <span>
              <br />
            </span>
          </span>
        </li>
        <li>
          <span>
            protect the rights or property of Little By Little Labs, LLC.
          </span>
        </li>
        <li>
          <span>
            prevent or investigate wrongdoing in connection with the Service
          </span>
        </li>
        <li>
          <span>protect against legal liability</span>
        </li>
      </ul>
      <br />
      <p>
        <strong>MANAGING PERSONAL INFORMATION</strong>
      </p>
      <p>
        <span>
          You may ask for your information and account to be deleted at any time
          by emailing a request to:
        </span>
        <a href="mailto:info@littlebylittlelabs.com">
          <span>info@littlebylittlelabs.com</span>
        </a>
        <span>
          . You may unsubscribe from our mailing list by clicking the
          Unsubscribe button at the bottom of our emails.&nbsp;
        </span>
      </p>
      <br />
      <p>
        <strong>CONFIDENTIALITY</strong>
      </p>
      <p>
        <span>
          We will never sell or license your personal information to any third
          party. However, our app and website engage with third party service
          providers to perform services such as maintenance, market research,
          payment processing, community management, customer service, and other
          such activities to ensure the best possible app experience. The
          information provided to these third party service providers will only
          be given access to the personal information required to perform their
          services on our behalf. We bear no responsibility for the actions of
          third party providers.&nbsp;
        </span>
      </p>
      <br />
      <p>
        <strong>THIRD-PARTY PAYMENT PROCESSING</strong>
      </p>
      <p>
        <span>
          All purchases made through Little By Little Labs, LLC., services will
          be processed through third-party applications (App Store, Shopify).
          These third party applications will process and transmit your credit
          card and billing information directly. Your information will be
          encrypted and not stored by Little By Little Labs, LLC. We are not
          responsible for how your information is collected by third party
          payment processors. You can find more information on how individual
          payment processors handle your personal information in their own
          Privacy Policies.&nbsp;
        </span>
      </p>
      <br />
      <p>
        <strong>PUBLIC POSTING</strong>
      </p>
      <p>
        <span>
          In order to build our community, Little by Little Labs, LLC., will
          participate in public services such as message boards, Facebook Groups
          and will post to various social media networks. When engaging with any
          public services or social media, use discretion. Any personal
          information that you disclose on these platforms can be collected and
          used by others. This information is not protected. While we want to
          maintain a respectful and supportive community, Little by Little Labs,
          LLC., is not responsible for any words or actions taken by users or
          the public on third-party public platforms.&nbsp;&nbsp;
        </span>
      </p>
      <br />
      <p>
        <strong>CALIFORNIA PRIVACY RIGHTS</strong>
      </p>
      <p>
        <span>
          Under the California Consumer Protection Act (CCPA), California
          residents are provided with specific rights in regards to their
          personal information. Below you can review our practices in connection
          to your CCPA rights or visit
        </span>
        <a href="https://oag.ca.gov/privacy/ccpa">
          <span>https://oag.ca.gov/privacy/ccpa</span>
        </a>
        <span>.</span>
      </p>
      <p>
        <span style={{ "text-decoration": "underline" }}>
          <span>Types of Information We Collect</span>
        </span>
      </p>

      <ul>
        <li>
          <span>
            Email (for Mailchimp email marketing purposes through our website)
          </span>
        </li>
        <li>
          <span>Nickname (an alias provided by the user)</span>
        </li>
        <li>
          <span>Age range</span>
        </li>
        <li>
          <span>
            Gender (including a &ldquo;Prefer not to say&rdquo; option)
          </span>
        </li>
        <li>
          <span>App usage and activity data </span>
        </li>
        <li>
          <span>
            Personal Information such as stress level description, sleeping
            habits, activity level and goals.
          </span>
        </li>
      </ul>

      <p>
        <span style={{ "text-decoration": "underline" }}>
          <span>How We May Use Information We Collect</span>
        </span>
      </p>

      <ul>
        <li>
          <span>To maintain and improve our products and services</span>
        </li>
        <li>
          <span>To correct any bugs or improve functionality</span>
        </li>
        <li>
          <span>
            To notify you of any updates of services through email marketing
          </span>
        </li>
        <li>
          <span>To provide quality customer service</span>
        </li>
        <li>
          <span>To analyze usage</span>
        </li>
      </ul>

      <p>
        <span style={{ "text-decoration": "underline" }}>
          <span>Right to Delete or Correct Personal Information</span>
          <span>
            <br />
          </span>
        </span>
        <span>
          <br />
        </span>
        <span>
          You have the right to request deletion or correction of the personal
          information you provide to us. You may delete your email address from
          our email marketing service by selecting &ldquo;Unsubscribe&rdquo; at
          the bottom of our newsletters. You may also make these requests by
          sending an email to:
        </span>
        <a href="mailto:info@easywellness.app.">
          <span>info@easywellness.app.</span>
        </a>
        <span>.</span>
      </p>
      <p>
        <span style={{ "text-decoration": "underline" }}>
          <span>Data Portability</span>
        </span>
      </p>
      <p>
        <span>
          You have the right to request that Little by Little Labs, LLC.,
          provide you with information regarding our data collection and use of
          your personal information from the last 12 months. You may only
          request this information twice within 12 months. You may request this
          information by sending an email to:{" "}
        </span>
        <a href="mailto:info@easywellness.app.">
          <span>info@easywellness.app.</span>
        </a>
        <span>.</span>
      </p>
      <p>
        <span style={{ "text-decoration": "underline" }}>
          Non Discrimination
        </span>
      </p>
      <p>
        <span>
          Little by Little Labs, LLC., will not discriminate against any user
          for exercising their CCPA rights.
        </span>
      </p>
      <br />
      <p>
        <strong>THIRD PARTY LINKS</strong>
      </p>
      <p>
        <span>
          Our Services may contain links to other sites. If you click on a
          third-party link, you will be directed to that site. These external
          sites are not operated by us. We have no control over, and assume no
          responsibility for the content, privacy policies, or practices of any
          third-party sites or services.
        </span>
      </p>
      <br />
      <p>
        <strong>CHILDREN&rsquo;S PRIVACY</strong>
      </p>
      <p>
        <span>
          Our Services do not address anyone under the age of 13. We do not
          knowingly collect personal identifiable information from children
          under 13. In the case we discover that a child under 13 has provided
          us with personal information, we immediately delete this from our
          servers. If you are a parent or guardian and you are aware that your
          child has provided us with personal information, please contact us so
          that we will be able to do necessary actions.
        </span>
      </p>

      <p>
        <span>Any questions may be directed to: </span>
        <a href="mailto:info@easywellness.app.">
          <span>info@easywellness.app.</span>
        </a>
        <span>.</span>
      </p>
    </div>
  );
}
