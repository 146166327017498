import { EZW_APP_STORE_URL, EZW_INSTAGRAM_URL, EZW_FACEBOOK_URL, LBL_URL } from '../constants'
import InstagramPng from "../images/instagram.png"
import FacebookPng from "../images/facebook.png"
import AppStoreImage from "../images/AppStore.png"

//*********************************************************************/

export const Footer = () =>  {

  //*********************************************************************/

  return (
    <div id="Footer">
      <a href={EZW_APP_STORE_URL} target="_blank" rel="noreferrer">
        <img id="AppStoreButton" src={AppStoreImage} alt="Download" />
      </a>
      <div id="Social">
        <a href={EZW_INSTAGRAM_URL} target="_blank" rel="noreferrer">
          <img src={InstagramPng} alt="" />
        </a>
        <a href={EZW_FACEBOOK_URL} target="_blank" rel="noreferrer">
          <img src={FacebookPng} alt="" />
        </a>
        </div>
      <div id="Contact"><a href = "mailto: contact@easywellness.app">contact@easywellness.app</a></div>
      <div className="Links">
        <a href="/terms">Terms of Service</a>{" | "} 
        <a href="/privacy">Privacy Policy</a>
      </div>
      <div id="Copyright">&#169; Copyright 2021, <a href={LBL_URL} target="_blank" rel="noreferrer">Little By Little Labs, LLC</a></div>
    </div>
  )

  //*********************************************************************/
}

