import React, { useState, useEffect } from "react"
import { useParams,useLocation  } from 'react-router-dom'
import { fetchContentFulData } from '../actions'
import { blogUrlParam } from "../utilities"
import Logo from "../images/Logo.png"
import { EZW_APP_STORE_URL } from '../constants'
import { Footer } from "./Footer"
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

//*********************************************************************/

export default function Blog() {
  
  //*********************************************************************/

  const [blog, setBlog] = useState({})
  const [htmlContent, setHtmlContent] = useState("")
  const [loading, setLoading] = useState(true)
  const params = useParams()

  const search = useLocation().search
  const mode = new URLSearchParams(search).get('mode') ?? "light"
  const source = new URLSearchParams(search).get('source') ?? "web"
  const [hasMounted, setHasMounted] = React.useState(false);

  let options = {
    renderNode: {
      ["paragraph"]: (node, next) => `<p>${next(node.content).replace(/\n/g, `</br>`)}</p>`,
      'embedded-asset-block': (node) => `<img src="${node.data.target.fields.file.url}"/>`
    }
  }

  useEffect(() => { setHasMounted(true) } ,[])

  //*********************************************************************/

  useEffect(() => {

    fetchContentFulData().then((feed) => {

      feed.items.forEach(blog => {
        
        if (blogUrlParam(blog.fields.title) === params.title) {

          setBlog(blog)
          
          setHtmlContent(documentToHtmlString(blog.fields.content, options))          

          return
        }
      })
      
      setLoading(false)
    })
  }, [])

  //*********************************************************************/

  if (!hasMounted || loading) { return null }

  return (
    <div id="Blog" className={`${mode}`}>
      
      {source === "web" && 
        (<div className="NavContainer">
          <div className="Nav">
            <a href="/"><img className="AppLogo" src={Logo} alt="EasyWellness" /></a>
            <div id="NavLinks">
              <div><a href={EZW_APP_STORE_URL} target="_blank" rel="noreferrer">Download</a></div>
            </div>
          </div>
        </div>
        )
      }

      {!loading && blog.fields.content && 
        (
        <div className="BlogContent">
          <div className="BlogHeader">{blog.fields.title}</div>
          <img src={blog.fields.bannerImage.fields.file.url} alt="thumb"/>
          <div className="BlogSubTitle">{blog.fields.subTitle}</div>

          <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
        </div>
        )
      }
      {source === "web" && <React.Fragment><Footer/></React.Fragment>}
    </div>
  )

  //*********************************************************************/

}