import { useRef, useState, useEffect } from "react";

//*********************************************************************/

export default function SlideInSection(props) {

  //*********************************************************************/

  const [isVisible, setVisible] = useState(false)
  const domRef = useRef()
  const options = { root: null, margin: "0px", threshold: 0.2 }

  //*********************************************************************/

  useEffect(() => {
    const callbackFunction = entries => {
      entries.forEach(entry => { setVisible(entry.isIntersecting) })
    }

    let observerRefValue = null
    const observer = new IntersectionObserver(callbackFunction, options)

    if (domRef.current) {
      observerRefValue = domRef.current
      observer.observe(domRef.current)
    }

    return () => {
      if (observerRefValue) observer.unobserve(observerRefValue)
    }
  });
  
  //*********************************************************************/

  return (
    <div className={`FadeInSection ${props.fromLeft ? 'left' : 'right'} ${isVisible ? 'isVisible' : ''}`} ref={domRef}>
      {props.children}
    </div>
  )

  //*********************************************************************/
}
